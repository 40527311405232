import React from 'react';
import { graphql } from 'gatsby';
import Dropdown from '../forms/Dropdown';
import Textarea from '../forms/Textarea';
import Email from '../forms/Email';
import '../../styles/components/form.scss';

class Form extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			fields: {},
			errors: {}
		}
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

	    if (typeof fields["email"] !== "undefined") {
	      let lastAtPos = fields["email"].lastIndexOf("@");
	      let lastDotPos = fields["email"].lastIndexOf(".");

	      if (
	        !(
	          lastAtPos < lastDotPos &&
	          lastAtPos > 0 &&
	          fields["email"].indexOf("@@") == -1 &&
	          lastDotPos > 2 &&
	          fields["email"].length - lastDotPos > 2
	        )
	      ) {
	        formIsValid = false;
	        errors["email"] = "Please enter a valid email address.";
	      }
	    }

	    this.setState({ errors: errors });
	    return formIsValid;

	}

	handleChange(field, e) {
    	let fields = this.state.fields;
    	fields[field] = e.target.value;
    	this.setState({ fields });
    	this.handleValidation();
  	}

	handleSubmit = (e) => {
	  // e.preventDefault();

		e.preventDefault();

	  	let feedback = document.querySelector( '.feedback' );
		let success = document.createElement( "p" );
		let message = document.createTextNode( "Thank you, form now submitted" );
		success.appendChild( message );

		  let myForm = document.getElementById("form");
		  let formData = new FormData(myForm);
		  fetch("/", {
		    method: "POST",
		    headers: { "Content-Type": "application/x-www-form-urlencoded" },
		    body: new URLSearchParams(formData).toString(),
		  })
		    .then(() => {
		    	console.log("Success");
				myForm.previousSibling.innerHTML = '';
				myForm.innerHTML = '';
				feedback.appendChild( success );
		    })
		    .catch((error) => alert(error));

	 //  	let feedback = document.querySelector( '.feedback' );
		// let success = document.createElement( "p" );
		// let message = document.createTextNode( "Thank you, form now submitted" );
		// success.appendChild( message );

		// let myForm = document.getElementById("form");
		// let formData = new FormData(myForm);
		
	 //    if (this.handleValidation()) {

		// 	fetch("/", {
		// 		method: "POST",
		// 		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		// 		body: new URLSearchParams(formData).toString(),
		// 	})
		// 	.then(() => {
		// 		myForm.previousSibling.innerHTML = '';
		// 		myForm.innerHTML = '';
		// 		feedback.appendChild( success );
		// 	})
		// 	.catch((error) => alert(error));

	 //    }
	
	};

	render() {

		return (
			<>
				<div className={ 'feedback' }></div>
			<form 	
				id="form"
				data-netlify="true" 
				name="portfolio" 
				className={ 'flex' }
			>
          		<input type="hidden" name="portfolio" value="contact" />

				{ this.props.fields.map((field, i) => {
					if( field.type === 'dropdown' ) {
						return (
							<Dropdown 
								key={i}
								choices={ field.choices } 
								placeholder={ field.placeholder } 
								label={ field.label }
							/>
						)
					}

					if( field.type === 'textarea' ) {
						return (
							<Textarea 
								key={i} 
								label={ field.label } 
								placeholder={ field.placeholder } 
							/>
						)
					}

					if( field.type === 'email' ) {
						return (
							<Email 
								key={i} 
								errors={ this.state.errors }
								changeHandler={ this.handleChange.bind(this, "email") }
								label={ field.label } 
								placeholder={ field.placeholder } 
							/>
						)
					}

				}) }

	 			<input 
	 				onClick={this.handleSubmit} 
	 				type="submit" 
	 				value="Submit request" 
	 			/>

			</form>
			</>
		);

	}

}

export default Form;