import React from 'react';

export default function Textarea( props ) {

	return (
		<div className={ 'dropdown-wrapper textarea-wrapper flex' }>
			<p>{ props.label }</p>
			<textarea rows="4" name={ props.placeholder } placeholder={ props.placeholder } /> 
		</div>
	);

}
