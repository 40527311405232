import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Form from './Form';
import '../../styles/components/modal.scss';
import '../../styles/components/close-modal.scss';

const showForm = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
    	delay: 0.5,
    }
  }
}

export default function Modal( props ) {
	return (
		<AnimatePresence>
			<motion.article 
				className={ 'modal flex text:section' }
				initial={{ backgroundColor: "rgba( 132, 48, 30, 0 )" }}
				animate={{ backgroundColor: "rgba( 87, 28, 15, 0.88 )" }}
				exit={{ backgroundColor: "rgba( 132, 48, 30, 0 )" }}
				>

				<AnimatePresence>
					<motion.div
						variants={ showForm }
						initial="hidden"
						animate="show"
						exit="hidden"
					>
						<div>
							<h2>PDF portfolio requests</h2>

							<a 
								onClick={(e) => {
									e.preventDefault();
									props.modalToggle();
								}}
								class="close-modal" href="#">
								<span className={ 'screen-reader-text' }>Close Popup</span>
							</a>
						</div>

						<Form fields={ props.modalContent } />
					</motion.div>
				</AnimatePresence>
			</motion.article>
		</AnimatePresence>
	);

}