import React from 'react';
import Img from 'gatsby-image';
import '../../styles/components/practice-image.scss';

export default function Image( props ) {

	return (
		<figure className={ 'practice-image' }>
			<img className={ 'cover' } src={ props.content.image.localFile.childImageSharp.fluid.src }/>
		</figure>
	);

}