import React, { useState } from "react";
import { graphql, StaticQuery, Link } from 'gatsby';
import { scrollToIndex } from '../helpers/scrollToIndex';
import { kebabCase } from '../helpers/kebabCase';
import TransitionLink from 'gatsby-plugin-transition-link'
import Layout from '../components/Layout';
import Header from '../components/Header';
import Section from '../components/practice/Section';
import SEO from '../components/SEO';
import ContactDetails from '../components/practice/ContactDetails';
import Modal from '../components/utils/Modal';
import Asterism from '../components/home/Asterism';
import Credits from '../components/utils/Credits';
import '../styles/components/practice.scss';
import '../styles/components/practice-navigation.scss';
import '../styles/components/social-media.scss';

let cover, transitionWrapper;

let theWindow = typeof window !== 'undefined' ? window : '';

class Practice extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			modalVisible: false,
			currentImage: null,
			windowWidth: null,
			credits: false,
			index: false,
			title: null
		}

		this.displayModal = this.displayModal.bind( this );
		this.setIndexVisibility = this.setIndexVisibility.bind( this );
	}

	componentDidMount = () => {

		this.setState({
			windowWidth: this.state.windowWidth = window.innerWidth
		});

    	let currentWidth = this.state.windowWidth;
    	
    	window.addEventListener( 'resize', (e) => {
    		if( window.innerWidth === currentWidth ) return;
    		this.handleResize();
    	});

	}

	findIndex = ( counter ) => {
		this.indexClose();

		setTimeout(() => {
			this.scrollToIndex( counter, 1500 );	
		}, 500);
	}


	setActiveAndReveal = (e) => {

		let target = e.currentTarget.getAttribute( 'href' );
		let panel = document.getElementById( target );
		let activePanel = document.querySelectorAll( '.active-panel' );
		let practiceNavigation = document.querySelector( '.practice-navigation' );
		let activeItem = document.querySelectorAll( '.active-nav-state' );
		let content = document.querySelector( 'article' );

		e.currentTarget.classList.add( 'active-nav-state' );
		panel.classList.add( 'active-panel' );

		content.scrollTop = 0;

		activePanel.forEach((item, index) => {
			item.classList.remove( 'active-panel' );
		});

		activeItem.forEach((item, index) => {
			item.classList.remove( 'active-nav-state' );
		});

	}

	handleResize = () => {

		this.setState({
			windowWidth: theWindow.innerWidth
		});

	}

	indexOpen = () => {
   	
	   	document.body.classList.remove( 'panel-visible' );
	   	this.setState({
	   		index: this.state.index = true,
	   		modal: this.state.modal = false
	   	});

	   	document.body.classList.add( 'has-nav' );

   	}

   	indexClose = () => {
		document.body.classList.remove( 'has-nav' );

		setTimeout(() => {
		   	this.setState({
		   		index: this.state.index = false
		   	})
		}, 10);
   	}

	setIndexVisibility = () => {
		this.setState({
			index: false
		});
	}

	toggleCredits = () => {

		this.setState({
			credits: this.state.credits = !this.state.credits
		})
	}

   	setTitle = ( title ) => {
		this.setState({
			title: this.state.title = title
		});
   	}

	displayModal = () => {

		this.setState({
			modalVisible: this.state.modalVisible = !this.state.modalVisible
		});		

		this.state.modalVisible ? document.body.classList.add( 'overflow' ) : document.body.classList.remove( 'overflow' );

	}

	render() {

		return (

			<>
			<StaticQuery 	
				query={graphql`
					query Practice {
					  wp {
					    generalSettings {
					      title
					      description
					    }
					    siteSettings {
					      settings {
					        address
					        email
					        phone
					      }
					    }
					  }
					  wpPage(id: {eq: "cG9zdDozMQ=="}) {
					    id
					    title
					    featuredImage {
					      node {
					        localFile {
					          childImageSharp {
					            fluid {
					              ...GatsbyImageSharpFluid
					              ...GatsbyImageSharpFluidLimitPresentationSize
					            }
					          }
					        }
					      }
					    }
					    form {
					      formFields {
					        choices {
					          choice
					          fieldGroupName
					        }
					        fieldGroupName
					        placeholder
					        type
					        label
					      }
					      fieldGroupName
					    }

					    practiceSections {
					      practice {
					        layout {
					          ... on WpPage_Practicesections_practice_Layout_Image {
					            fieldGroupName
					            image {
					              id
					              localFile {
					                childImageSharp {
					                  fluid {
					                    ...GatsbyImageSharpFluid
					                    ...GatsbyImageSharpFluidLimitPresentationSize
					                  }
					                }
					              }
					            }
					          }
					          ... on WpPage_Practicesections_practice_Layout_Text {
					            fieldGroupName
					            text
					          }
					          ... on WpPage_Practicesections_practice_Layout_Panel {
					            fieldGroupName
					            panel
					          }
					          ... on WpPage_Practicesections_practice_Layout_ContactDetails {
					            address
					            callToActionText
					            email
					            fieldGroupName
					            phone
					          }
					        }
					        sectionTitle
			                shortSectionTitle
					        sectionThumbnail {
					          localFile {
					            childImageSharp {
					                fluid {
					                  ...GatsbyImageSharpFluid
					                  ...GatsbyImageSharpFluidLimitPresentationSize
					                }
					            }
					          }
					        }
					      }
					    }
					    instagramUrl {
      						instagramUrl
    					}
					  }
					}
				`
				}
				render={ (practice)  => (
					<>
						<SEO
							bodyClasses="bg:accent"
							siteTitle={ practice.wp.generalSettings.title }
							siteDescription={ practice.wp.generalSettings.description }
							title={ practice.wpPage.title } 
						/>

						<Layout>
						<Header
							setTitle={ this.setTitle }
							findIndex={ scrollToIndex }
							indexVisibility={ this.state.index }
							navVisibility={ this.indexClose }
							parentPage={ 'practice' }
							indexOpen={ this.indexOpen }
						/>

							<div onClick={(e) => {
								if( this.state.index === true ) {
									this.indexClose();
								}
							}} className="practice flex">
								<aside className="flex">
									<nav>
										{practice.wpPage.practiceSections && (
											<ul className={ 'practice-navigation flex uppercase text:new-copy' }>

												{ practice.wpPage.practiceSections.practice.map((section, i) => (
													<li 
														key={i}>
														<a 	
															className={ i === 0 && this.state.windowWidth > 630 || i === 0 && this.state.windowWidth < 630 ? 'active-nav-state practice-navigation-item' : 'practice-navigation-item' }
															data-link-count={ i }
															data-link={ kebabCase( section.sectionTitle ) } 
															href={ kebabCase( section.sectionTitle ) } 
															onClick={(e) => { 
																e.preventDefault();
																e.stopPropagation();
																this.setActiveAndReveal(e);
														 }}>
														 { section.shortSectionTitle &&  (
														 	<>
														 	<span className={ 'mobile' }>{ section.shortSectionTitle }</span>
														 	<span className={ 'desktop' }>{ section.sectionTitle }</span>
														 	</>
														 )}
														 {!section.shortSectionTitle && (
														 	section.sectionTitle
														 )}
														</a>
													</li>
												))}
											</ul>
										)}

									</nav>

								</aside>
									
								{practice.wpPage.practiceSections && (
									<article>
									
										{ practice.wpPage.practiceSections.practice.map((section, i) => (
											<Section 
												displayModal={ this.displayModal }
												windowWidth={ this.state.windowWidth }
												content={ section }
												counter={ i }
												key={ i }
											/>
										))}

										<Credits />
									</article>
								)}
							{ practice.wpPage.instagramUrl.instagramUrl && (
								<p className={ 'social-media uppercase text:md text:tracked' }>
									Follow <a target="_blank" rel="noreferrer" href={ practice.wpPage.instagramUrl.instagramUrl }>@Mooradian_Studio</a>
								</p>
							)}


							</div>

							{this.state.modalVisible == true && (
								<Modal 
									modalContent={ practice.wpPage.form.formFields } 
									modalToggle={ this.displayModal } 
								/>
							)}

							<Asterism 
									indexVisibility={ this.state.index }
									handleIndex={ this.indexOpen }
									handleClose={ this.indexClose }
								/>

						</Layout>
					</>
				)}
			/>
			</>

		);

	}

}

export default Practice;