import React from 'react';
import Text from './Text';
import Image from './Image';
import PullOut from './PullOut';
import ContactDetails from './ContactDetails';
import ContactSection from './ContactSection';
import { kebabCase } from '../../helpers/kebabCase';

export default function Section( props ) {
	return (
		<>
			<section 
				id={ kebabCase( props.content.sectionTitle ) } 
				data-section={ props.counter } 
				className={ props.counter === 0 && props.windowWidth > 630 || props.counter === 0 && props.windowWidth < 630 ? 'text:section content active-panel' : 'text:section content' }
				key={ props.counter }
			>

				{ props.content.layout.map((content, i) => {
					if( content.fieldGroupName === 'Page_Practicesections_practice_Layout_Image' ) {
						return (
							<Image content={ content } />
						);
					}
					if( content.fieldGroupName === 'Page_Practicesections_practice_Layout_Text' ) {
						return (
							<Text content={ content } />
						);
					}

					if( content.fieldGroupName === 'Page_Practicesections_practice_Layout_Panel' ) {
						return (
							<PullOut content={ content } />
						);
					}
					
					if( content.fieldGroupName === 'Page_Practicesections_practice_Layout_ContactDetails' ) {
						return (
							<ContactSection 
								content={ content } 
								displayModal={ props.displayModal }
							/>
						);
					}

				}) }

			</section>
		</>
	);

} 