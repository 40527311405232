import React from 'react';
import '../../styles/components/contact-details.scss';

export default function ContactSection( props ) {

	let phoneLink

	console.log(props);

	if( props.content.phone ) {
		phoneLink = props.content.phone.replace(/[- )(]/g,'');
	}

	return (
		<>
		<div className="contact-details">
				
				{props.content.address && (
					<div 
						dangerouslySetInnerHTML={{ __html: props.content.address }}
					/>
				)}

				{ props.content.callToActionText && (
					<p>
						<a 
							onClick={(e) => {
								e.preventDefault();
								props.displayModal();
							}}
							aria-label="Opens contact form" href="#contact-form">{ props.content.callToActionText }</a>
					</p>
				)}

				{props.content.email && (
					<p>
						<a href={ "mailto:" + props.content.email }>{ props.content.email }</a>
					</p>			
				)}

				{props.content.phone && (
					<p>
						<a href={ "tel:" + phoneLink }>
							{ props.content.phone }
						</a>
					</p>			
				)}
			</div>
		</>
	);

}