import React, { useState } from 'react';

export default function Dropdown( props ) {
	const [isOpen, setIsOpen] = useState(false);
  	const [selectedOption, setSelectedOption] = useState(null);
  	const toggling = () => setIsOpen(!isOpen);
	const onOptionClicked = value => () => {
	    setSelectedOption(value);
	    setIsOpen(false);
 	 };

	return (
		<>
		<div className={ 'dropdown-wrapper flex' }>
		<p>{ props.label }</p>
			<div className="dropdown-container">
				<input style={{ visibility: 'hidden', width: '0', height: '0', padding: '0', margin: '0', position: 'absolute' }} className={ 'dropdown-selected' } type="text" name={ props.placeholder } value={ selectedOption } />
					<div onClick={toggling} className={ selectedOption ? "dropdown-header" : "dropdown-header"}>

					{ selectedOption || props.placeholder }
					</div>
					    {isOpen && (

						<div className="dropdown-list-container">
						<ul className="dropdown-list">
						{ props.choices.map((choice, i) => (
							<li onClick={onOptionClicked(choice.choice)} key={i}>{ choice.choice }</li>
						)) }
						</ul>
					</div>
					)}
				</div>
			</div>
		</>
	);

}
