import React from 'react';

export default function Email( props ) {

	return (
		<>
			<div className={ 'dropdown-wrapper textarea-wrapper flex' }>
				<p>{ props.label }</p>
				<input type="email" name={ props.placeholder } placeholder={ props.placeholder } /> 

				{ props.errors[ "email" ] && (
					<span className={ 'form-feedback text:header' }>{props.errors["email"]}</span>
				)}
			</div>
		</>
	);

}