import React from 'react';
import '../../styles/components/asterism.scss';
import '../../styles/components/close-index.scss';

export default function Asterism( props ) {

	return (
		<>
			<p className={ props.indexVisibility ? 'asterism text:counter toggled' : 'asterism text:counter'}>
				<a 
					onClick={(e) => {
					e.preventDefault();
					{ props.navVisibility ? props.handleClose() : props.handleIndex() }
				}} href="#">
					<span className={ 'asterism-label' }>{ props.navVisibility ? '\u00D7' : "\u2042" }</span>
				</a>
			</p>
		</>
	);

}